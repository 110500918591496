<template>
  <section>
    <div class="w-full bg-white p-4 rounded-lg mt-4">
      <Toast />
      <div class="w-full flex justify-between">
        <Button @click="$router.push({name: 'pharmasan.ventas.facturacion.facturacion-masiva.historial'})" class="bg-white border-0 text-blue-800 font-bold bg-gray-300" style="height: fit-content;" icon="pi pi-chevron-left" label="Atras" />
        <div class="flex items-center gap-2 mb-4 mt-4">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#1C3FAA" d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />
          </svg>
          <p class="text-lg">Historial detalle</p>
        </div>
      </div>
      <div class="lg:flex justify-between">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full md:w-auto items-center">
          <div class="w-full bg-white p-2">
            <label for="">Autor</label>
            <p class="font-bold">{{ $route.query.autor }}</p>
          </div>
          <div class="w-full bg-white p-2">
            <label for="">Fecha de envio</label>
            <p class="font-bold">{{ dayjs($route.query.fecha).format('DD-MM-YYYY') }}</p>
          </div>
          <div class="w-full bg-white p-2">
            <label for="">Tipo</label>
            <p class="font-bold">{{ +$route.query.tipo === 1 ? 'Evento' : ($route.query.tipo === 2 ? 'Evento/Capita' : 'Capita' ) }}</p>
          </div>
          <div class="w-full bg-white p-2">
            <label for="">Cliente</label>
            <p class="font-bold">{{ $route.query.cliente }}</p>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 items-center justify-end items-center">
            <button class="rounded-lg border flex text-left lg:text-center items-center mx-5 card-detalles" @click="filtroEstado(4)">
                <div class="p-3 bg-gray-800 h-14 w-12 rounded-l-lg flex justify-center items-center">
                    <img class="h-3 w-3" src="../../../../../../../public/img/icon_todos_historial.svg" alt="Icono historial" />
                </div>
                <div class="p-2">
                    <p>Todos</p>
                    <p class="font-bold">{{totales}}</p>
                </div>
            </button>
            <button @click="filtroEstado(0)" class="rounded-lg border flex items-center mx-5 card-detalles text-left lg:text-center">
                <div style="background-color: #531EAF" class="p-3 h-14 w-12 rounded-l-lg flex justify-center items-center">
                    <img class="h-5 w-5" src="../../../../../../../public/img/icon_historial_procesando.svg" alt="Icono historial" />
                </div>
                <div class="p-2">
                    <p>Procesando</p>
                    <p class="font-bold">{{proceso}}</p>
                </div>
            </button>
            <button  @click="filtroEstado(1)" class="rounded-lg border items-center flex mx-5 card-detalles text-left lg:text-center">
                <div style="background-color: #8BC34A" class="p-3 h-14 w-12 rounded-l-lg flex justify-center items-center">
                    <img class="h-6 w-6" src="../../../../../../../public/img/icon_historial_generados.png" alt="Icono historial" />
                </div>
                <div class="p-2">
                    <p>Generados</p>
                    <p class="font-bold">{{facturadas}}</p>
                </div>
            </button>
            <button @click="filtroEstado(2)" class="rounded-lg border items-center flex mx-5 card-detalles text-left lg:text-center">
                <div class="p-3 h-14 w-12 bg-red-500 rounded-l-lg flex justify-center items-center">
                    <img class="h-6 w-6" src="../../../../../../../public/img/icon_historial_errores.png" alt="Icono historial" />
                </div>
                <div class="p-2">
                    <p>Errores</p>
                    <p class="font-bold">{{error}}</p>
                </div>
            </button>
        </div>
      </div>
      <!-- Filtros -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 lg:gap-4 mt-8">
        <InputText type="text" style="height: fit-content" placeholder="Buscar grupo" v-model="filtros.id" />
        <div>
          <InputText class="w-full h-fit" type="text" @keyup.enter="guardarOrdenes" placeholder="Ordenes" v-model="ordenSeleccionada" />
          <div class="w-full flex flex-wrap gap-2 mt-2 max-h-20 overflow-y-auto">
            <div
              v-for="(orden, i) in filtros.ordenes"
              :key="i"
              class="max-h-10 overflow-y-auto">
              <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                <button @click="borrarOrdenes(orden)" class="text-blue-800 mr-2">
                  <i style="font-size: 0.6rem" class="pi pi-times"></i>
                </button>
                <p style="inline-size: max-content;">{{orden}}</p>
              </div>
            </div>
          </div>
        </div>
        <div style="height: fit-content" class="grid grid-cols-2 gap-x-2 h-fit">
          <Button @click="getDetalle" icon="pi pi-search" label="Buscar" />
          <Button label="Limpiar" @click="limpiarFiltros" class="bg-gray-200 border-0 text-gray-600 hover:bg-red-300" />
        </div>
      </div>
      <DataTable :value="listadoEnvios" responsiveLayout="scroll" rowGroupMode="rowspan"  groupRowsBy="facturacionMasivaDetalleId" class="mt-4">
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="facturacionMasivaDetalleId" header="GRUPO ID"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="NumOrden" header="#OV"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" header="TOTAL OV">
            <template  #body="{data}">
              <p>{{$h.formatCurrency(data.TotalOv)}}</p>
            </template>
          </Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs max-w-xs" field="Comentarios" header="COMENTARIOS"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="facturacionMasivaDetalleId" header="NUMERO DE FACTURA">
            <template #body="{data}">
              {{data.NumFactura}}
            </template>
          </Column>
          <Column
          header="ESTADO"
          headerClass="lg:text-xs"
          bodyClass="lg:text-xs"
          field="facturacionMasivaDetalleId"
          >
            <template #body="{data}">
              <div v-if="data.Estado === 2">
                <button @click="reenviarOrdenes(data.facturacionMasivaDetalleId)" class="p-2 rounded-md bg-red-200 text-red-600 font-medium">
                  Reenviar
                </button>
              </div>
              <div v-else>
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                    <path :fill="data.Estado === 0 ? 'rgb(83, 30, 175)' : (data.Estado === 1 ? 'rgb(139, 195, 74)' : 'red')" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <i class="pi pi-circle-fill" :class="data.Estado === 0 ? 'text-purple-600' : (data.Estado === 1 ? 'text-green-600' : 'text-red-600')"></i>
              </div>
            </template>
          </Column>
      </DataTable>
      <div class="lg:flex justify-center gap-4 items-center text-center mt-2">
        <p class="text-gray-600">
          Mostrando de {{totalItemsCount > 1 ? (offset +1) : offset}} a {{totalItemsCount > limit ? (listadoEnvios.length === limit ? limit + offset : listadoEnvios.length + offset) : totalItemsCount }} de {{totalItemsCount}}
        </p>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="totalItemsCount"
          :rowsPerPageOptions="[2,10,20,30, 100, 500]"
          @page="onPage($event)"
        />
      </div>
    </div>
  </section>
</template>
<script>
    import { onMounted, ref, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import HistorialService from '../../../services/historial.service'
    import dayjs from 'dayjs'
    import { useToast } from 'primevue/usetoast'
    export default {
      name: 'HistorialDetalleFacturacion',
      setup () {
        // Services
        const _HistorialService = ref(new HistorialService())
        const route = useRoute()
        // References
        const limit = ref(10)
        const offset = ref(0)
        const page = ref(0)
        const totalItemsCount = ref(0)
        const totales = ref(0)
        const proceso = ref(0)
        const error = ref(0)
        const facturadas = ref(0)
        const ordenSeleccionada = ref('')
        const listadoEnvios = ref([])
        const toast = useToast()
        // Data
        const filtros = ref({
          id: '',
          ordenes: [],
          Estado: 4
        })
        const modalidades = ref([
          {
            name: 'Evento',
            id: 1
          },
          {
            name: 'Capita',
            id: 2
          },
          {
            name: 'Evento/Capita',
            id: 3
          }
        ])
        // Computed
        const params = computed(() => {
          return {
            offset: offset.value,
            page: page.value,
            limit: limit.value,
            filters: filtros.value
          }
        })
        // Methods
        const getDetalle = () => {
          _HistorialService.value.find(+route.params.id, params.value).then(({ data }) => {
            listadoEnvios.value = data.rows
            totalItemsCount.value = data.count
            proceso.value = data.proceso
            error.value = data.error
            facturadas.value = data.generadas
            if (filtros.value.Estado === 4) {
              totales.value = data.count
            }
          })
        }
        const guardarOrdenes = () => {
          for (const orden of ordenSeleccionada.value.split(' ')) {
            filtros.value.ordenes.length ? (
              orden === '' ? filtros.value.ordenes.push() : (
                filtros.value.ordenes.some(a => a === orden) ? filtros.value.ordenes.push() : filtros.value.ordenes.push(orden)
              )
            )
            : filtros.value.ordenes.push(orden)
          }
          ordenSeleccionada.value = ''
        }
        const borrarOrdenes = (orden) => {
          const index = filtros.value.ordenes.findIndex(a => a === orden)
          filtros.value.ordenes.splice(index, 1)
        }
        const filtroEstado = (estado) => {
          filtros.value = {
            id: '',
            ordenes: [],
            Estado: estado
          }
          getDetalle()
        }
        const limpiarFiltros = () => {
          filtros.value = {
            id: '',
            ordenes: [],
            Estado: 4
          }
        }
        const reenviarOrdenes = (id) => {
          _HistorialService.value.reenviar(id).then(() => {
            getDetalle()
            toast.add({ severity: 'success', summary: 'Reenviado', detail: 'Grupo de ordenes reenviado con éxito', life: 5000 })
          })
        }
        const onPage = ({ first, page, pageCount, rows }) => {
          limit.value = rows
          getDetalle()
        }
        onMounted(() => {
          getDetalle()
        })
        return {
          filtros,
          listadoEnvios,
          ordenSeleccionada,
          guardarOrdenes,
          borrarOrdenes,
          modalidades,
          limpiarFiltros,
          totalItemsCount,
          onPage,
          limit,
          offset,
          page,
          dayjs,
          filtroEstado,
          proceso,
          error,
          facturadas,
          totales,
          getDetalle,
          reenviarOrdenes
        }
      }

    }
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
</style>
