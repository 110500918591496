import http from '@/libs/http'
export default class HistorialService {
  getHistorial (params) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/historial`, { params, headers: { loading: true } })
  }

  get (params) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/historial`, { params, headers: { loading: true } })
  }

  find (id, params) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/historial/${id}`, { params, headers: { loading: true } })
  }

  autores () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/historial/autores`, { headers: { loading: true } })
  }

  reenviar (id) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturas/${id}`)
  }

  cancelarEnvio (params) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/historial/cancelar-envio`, { params, headers: { loading: true } })
  }
}
